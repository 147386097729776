import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { useState } from 'react'
import { ReactComponent as LineChart } from '@/assets/linechart.svg'
import { ReactComponent as BarChart } from '@/assets/barchart.svg'
import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { ArrowUp } from 'lucide-react'
import AccountBarChart from './AccountBarChart'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AccountLineChart from './AccountLineChart'

const chartType = {
    barChart: 'bar-chart',
    lineChart: 'line-chart',
}
const AccountTransactionsChart = () => {
    const [yearFilter, setYearFilter] = useState('2024')
    const year = new Date().getFullYear()
    const [searchParams] = useSearchParams()
    const chartValType = searchParams.get('chartType') ?? chartType.barChart
    const navigate = useNavigate()

    const addChartTypeParam = (name: string) => {
        const urlParams = new URLSearchParams(location.search)
        urlParams.set('chartType', name)

        navigate({
            pathname: location.pathname,
            search: urlParams.toString(),
        })
    }
    return (
        <section className='bg-white rounded-lg px-3 lg:px-5 p-5'>
            <div className='flex flex-col lg:flex-row lg:items-center justify-between gap-4 lg:gap-10'>
                <h3 className='text-lg font-medium'>Money in vs Money out</h3>
                <div className='flex lg:justify-between gap-4'>
                    <Select onValueChange={setYearFilter} value={yearFilter}>
                        <SelectTrigger className='border-gray8 text-gray7 w-28'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent className='h-fit max-h-[10rem] w-[13rem]'>
                            <SelectItem value={year.toString()}>This year</SelectItem>
                            <SelectItem value={(year - 1).toString()}>Last year</SelectItem>
                        </SelectContent>
                    </Select>

                    <Tabs value={chartValType} className='border bg-gray3/20 border-gray rounded-md'>
                        <TabsList className='border-none pt-0 h-full flex items-center px-2'>
                            <TabTriggersContainer className='border-none justify-center gap-4'>
                                <TabsTrigger
                                    onClick={() => addChartTypeParam(chartType.barChart)}
                                    value={chartType.barChart}
                                    className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                >
                                    <BarChart className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                </TabsTrigger>
                                <TabsTrigger
                                    onClick={() => addChartTypeParam(chartType.lineChart)}
                                    value={chartType.lineChart}
                                    className='border-none group data-[state=active]:bg-black h-fit py-0 p-1 rounded'
                                >
                                    <LineChart className='stroke-gray7/60 group-data-[state=active]:stroke-white' />
                                </TabsTrigger>
                            </TabTriggersContainer>
                        </TabsList>
                    </Tabs>
                </div>
            </div>

            <section className='mt-10 grid grid-cols-1 lg:grid-cols-4'>
                <div className='space-y-8 col-span-1 flex flex-col justify-center lg:items-center lg:border-r border-gray/60 py-6'>
                    <ul className='space-y-4  border-b border-gray/60 pb-8'>
                        <li className='text-gray7 space-y-0.5'>
                            <h4 className=''>Total money in</h4>
                            <h3 className='font-bold text-3xl'>₦2,109,312.00</h3>
                            <p className='text-sm font-medium flex gap-1 !mt-1.5'>
                                <span className='flex gap-1.5 text-brightGreen'>
                                    <ArrowUp size={15} />
                                    23.36%
                                </span>
                                compared to previous year
                            </p>
                        </li>
                        <li className='text-gray7 space-y-0.5'>
                            <h4 className=''>Total money out</h4>
                            <h3 className='font-bold text-3xl'>₦2,109,312.00</h3>
                            <p className='text-sm font-medium flex gap-1 !mt-1.5'>
                                <span className='flex gap-1.5 text-errorRed'>
                                    <ArrowUp size={15} />
                                    23.36%
                                </span>
                                compared to previous year
                            </p>
                        </li>
                    </ul>
                    <ul className='space-y-4'>
                        <li className='text-gray7 space-y-0.5'>
                            <h4 className=''>Average money in</h4>
                            <h3 className='font-bold text-3xl'>₦2,109,312.00</h3>
                        </li>
                        <li className='text-gray7 space-y-0.5'>
                            <h4 className=''>Average money out</h4>
                            <h3 className='font-bold text-3xl'>₦2,109,312.00</h3>
                        </li>
                    </ul>
                </div>
                <div className='col-span-3 lg:ml-6'>
                    {chartValType === chartType.barChart && <AccountBarChart />}
                    {chartValType === chartType.lineChart && <AccountLineChart />}
                </div>
            </section>
        </section>
    )
}

export default AccountTransactionsChart

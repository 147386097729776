export const CustomTooltip: React.FC<{
    active?: boolean
    payload?: any[]
    label?: string
}> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { name } = payload[0].payload

        return (
            <div className='bg-[#30313F] rounded-md p-3 text-[#F2F2F2] w-40 text-sm font-medium'>
                <h4 className='mb-2'>{name}</h4>
                {payload.map((entry, index) => {
                    const { fill } = entry
                    const { dataKey, value } = entry

                    return (
                        <p key={index} className='flex items-center gap-2 mt-1.5 justify-between'>
                            <span className='flex gap-1.5 items-center'>
                                <span className='w-3 h-3 rounded-full inline-block' style={{ background: fill }} />
                                {dataKey.toUpperCase()}:
                            </span>
                            {value}
                        </p>
                    )
                })}

                <span
                    className='bg-[#30313F] absolute block w-8 h-8 rotate-180 -bottom-6 -translate-x-1/2 left-1/2'
                    style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }}
                />
            </div>
        )
    }

    return null
}

export const CustomLegend: React.FC<any> = ({ payload }) => {
    return (
        <div className='flex gap-8 items-center justify-center !mt-12'>
            {payload.map((entry: any, index: number) => (
                <div key={index} className='flex items-center'>
                    <span className='w-5 h-5 rounded' style={{ backgroundColor: entry.color }} />
                    <span className='ml-2 text-sm font-medium'>{entry.value}</span>
                </div>
            ))}
        </div>
    )
}

import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { CustomLegend, CustomTooltip } from './AccountCustom'

type DataPoint = {
    name: string
    uv: number
    pv: number
}

const data: DataPoint[] = [
    { name: 'Page A', uv: 4000, pv: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398 },
    { name: 'Page C', uv: 2000, pv: 9800 },
    { name: 'Page D', uv: 2780, pv: 3908 },
    { name: 'Page E', uv: 1890, pv: 4800 },
    { name: 'Page F', uv: 2390, pv: 3800 },
    { name: 'Page G', uv: 3490, pv: 4300 },
]

const AccountBarChart: React.FC = () => {
    return (
        <ResponsiveContainer width='100%' height='100%'>
            <BarChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray='10 10' vertical={false} />
                <XAxis
                    dataKey='name'
                    tick={{ fontSize: 13, fill: '#5E5E5E', fontWeight: '500' }}
                    axisLine={{
                        stroke: '#DADCE0',
                    }}
                />
                <YAxis
                    label={{
                        value: 'Transactions in Naira',
                        angle: -90,
                        position: 'insideLeft',
                        offset: -10,
                        fill: '#202020',
                        dy: 80,

                        fontSize: 16,
                    }}
                    tickLine={false}
                    axisLine={false}
                />
                <Tooltip content={CustomTooltip} cursor={false} />
                <Legend content={CustomLegend} />
                <Bar dataKey='pv' fill='#454ADE' radius={[4, 4, 0, 0]} barSize={35} />
                <Bar dataKey='uv' fill='#D5D5F5' radius={[4, 4, 0, 0]} barSize={35} />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default AccountBarChart

import React from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts'
import { CustomLegend, CustomTooltip } from './AccountCustom'

const data = [
    { name: 'Page A', pv: 2400, uv: 2400 },
    { name: 'Page B', pv: 1398, uv: 2210 },
    { name: 'Page C', pv: 9800, uv: 2290 },
    { name: 'Page D', pv: 3908, uv: 2000 },
    { name: 'Page E', pv: 4800, uv: 2181 },
    { name: 'Page F', pv: 3800, uv: 2500 },
    { name: 'Page G', pv: 4300, uv: 2100 },
]

const AccountLineChart: React.FC = () => {
    return (
        <ResponsiveContainer width='100%' height={400}>
            <AreaChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray='10 10' vertical={false} />
                <XAxis
                    dataKey='name'
                    tick={{ fontSize: 13, fill: '#5E5E5E', fontWeight: '500' }}
                    axisLine={{
                        stroke: '#DADCE0',
                    }}
                />
                <YAxis
                    tickLine={false}
                    axisLine={false}
                    label={{
                        value: 'Transactions in Naira',
                        angle: -90,
                        position: 'insideLeft',
                        offset: -10,
                        fill: '#202020',
                        dy: 80,

                        fontSize: 16,
                    }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend content={CustomLegend} />

                <Area
                    type='monotone'
                    dataKey='pv'
                    stroke='#454ADE'
                    fill='#165DFF'
                    fillOpacity={0.1}
                    strokeWidth={2}
                    dot={false}
                    activeDot={false}
                />
                <Area
                    type='monotone'
                    dataKey='uv'
                    stroke='#D5D5F5'
                    fill='#D5D5F5'
                    strokeWidth={2}
                    dot={false}
                    fillOpacity={0}
                    activeDot={false}
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default AccountLineChart
